<template>
  <li>
    <a :href="to_ref" class="nav-link scrollto">
      {{ txt }}
    </a>
  </li>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    to_ref: {
      type: String,
    },
    txt: {
      type: String,
    },
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped></style>
