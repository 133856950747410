<template>
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="footerLinks">
            <ul>
              <li><a href="/#" class=" ">Home </a></li>
              <li><a href="/#about" class=" ">About </a></li>
              <li><a href="/#download" class=" ">Download </a></li>
              <li><a href="/#contact" class=" ">Contact </a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-6 footer-links"></div>

          <div class="col-lg-2 col-md-6 footer-links"></div>

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>Açık Deniz Bilişim</h3>
              <p>
                Barbaros Mah. Akzambak Sok. <br />
                Uphill Court A1 Blok No:3 Kat:4 D:22 34746 <br />
                Batı Ataşehir/İstanbul <br /><br />
                <strong>Phone: </strong> +90 216 688 85 67<br />
                <strong>E-mail: </strong> info@acikdenizbilisim.com<br />
                <strong>Website: </strong
                ><a href="https://acikdenizbilisim.com" target="blank">
                  acikdenizbilisim.com </a
                ><br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Açık Deniz Bilişim</span></strong
        >.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footerLinks {
  text-align: center;
}
.footerLinks ul {
  padding: 0;
  list-style-type: none;
  margin: 20;
}
.footerLinks li {
  display: inline;
  padding: 0 21px;
  border-left: 1px solid #ff0000;
  border-right: 1px solid #ff0000;
}
.footerLinks a {
  color: #fff;
  text-decoration: none;
  /*font-size: 13px;*/
}
.footerLinks li + li:before {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  font-size: 0;
}
.footer-info a {
  color: white;
  text-decoration: underline;
}
.footer-info a:hover {
  text-decoration: none;
}
</style>
