import { createRouter, createWebHistory } from "vue-router";

import Home from "../components/Home.vue";
import enHome from "../components/enHome.vue";
import Gizlilik from "../components/Gizlilik.vue";
import enGizlilik from "../components/enGizlilik.vue";
// import Aksiyon from "../components/Aksiyon.vue";
// import Ambar from "../components/Ambar.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/gizlilik",
    name: "Gizlilik",
    component: Gizlilik,
  },
  {
    path: "/enhome",
    name: "enHome",
    component: enHome,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
