<template>
  <!-- ======= About Section ======= -->
  <section ref="about" id="about" class="about">
    <div class="container">
      <div class="section-title">
        <span>Açık Deniz Bilişim</span>
        <h2>Açık Deniz Bilişim</h2>
      </div>
      <div class="row">
        <div class="" data-aos="fade-right">
          <p>
            Açık Deniz Bilişim ve Danışmanlık A.Ş.; 2008 yılında Uşak’ta
            kurulmuş olup, 2011’de “Türk Messenger” uygulamasıyla adını
            duyurmuştur. 2013 yılında yeniden yapılanmaya giden Firma, Akçay
            Şirketler Grubu’na dahil olmuştur.<br /><br />

            2013 yılından itibaren İstanbul’da Arge faaliyetlerini yürütmeye
            başlayan Açık Deniz A.Ş.; Turkcell Fizy, Hürriyet E-Gazete, Sigorta
            Bilgi Merkezi (SBM) gibi ülkemizde çok yoğun kullanılan mobil proje
            uygulamalarını gerçekleştirmiştir.<br /><br />

            Açık Deniz A.Ş., uluslararası bilgi güvenliği yönetim sistemi
            standartlarına uygunluğu belgeleyen “ISO-27001 Sertifikası”na
            sahiptir. 2015 yılından itibaren SİPER adlı Kurum içi Şifreli Mobil
            Haberleşme uygulamasını geliştirmeye başlamıştır. 2018 yılında
            geliştirmeleri tamamlayarak, UITSEC Test Firması tarafından
            gerçekleştirilen ürüne ait güvenlik ve penetrasyon (sızdırmazlık)
            testlerinden başarı ile geçmiştir.<br /><br />

            Açık Deniz A.Ş., 2018 yılında güvenli dosya arşivleme ve paylaşım
            sistemi olan AMBAR isimli ürünü geliştirmiştir. Bu ürün sayesinde,
            SİPER ve diğer uygulamalardan kurum içi ve kurum dışına paylaşılan
            tüm dosyalar güvenlik altına alınabilmektedir.<br /><br />

            Ayrıca, Açık Deniz A.Ş.’ye ait kurum içi yönetişim yazılımı olan
            AKSİYON isimli ürün sayesinde; hem SİPER, hem AMBAR için bir yönetim
            sistemi ve kuruma ait tüm işlerin yönetilebileceği bir ürün
            geliştirilmiştir.<br /><br />

            Açık Deniz A.Ş., halen yapay zeka yazılımı ile saldırı tespiti ve
            önleme çalışmalarına devam etmektedir.<br /><br />

            Açık Deniz A.Ş., ile Havelsan A.Ş. arasında 2018 yılında İş
            Ortaklığı Sözleşmesi imzalanmıştır. Ayrıca, Bilgi Güvenliği
            Derneği’ne, SaSaD’a ve Türkiye Siber Güvenlik Kümelenmesi’ne üyedir.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped></style>
