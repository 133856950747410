<template>
  <TopBar />

  <enHeader />

  <enHero />

  <main id="main">
    <enSiper />
    <enAbout />
    <enDownload />
    <enCta />
    <enContact />
  </main>

  <enFooter />

  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  components: {
    enSiper,
    enFooter,
    enContact,
    enCta,
    enDownload,
    enAbout,
    enHeader,
    enHero,
    TopBar,
  },
  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    btnClick() {},
    navigate() {
      router.push({ name: "Page1" });
    },
  },
};

import enHeader from "./enHeader";
import TopBar from "./topbar/TopBar";
import enHero from "./content/enHero";
import enDownload from "./content/enDownload";
import enAbout from "./content/enAbout";
import enCta from "./content/enCta";
import enContact from "./content/enContact";
import enFooter from "./enFooter";
import enSiper from "./content/enSiper";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
</style>
