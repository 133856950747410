<template>
  <TopBar />

  <Header />

  <Hero />

  <main id="main">
    <Siper />
    <About />
    <Download />
    <Cta />
    <Contact />
  </main>

  <Footer />

  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  components: {
    Siper,
    Footer,
    Contact,
    Cta,
    Download,
    About,
    Header,
    Hero,
    TopBar,
  },
  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    btnClick() {},
    navigate() {
      router.push({ name: "Page1" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Hero from "./content/Hero";
import Download from "./content/Download";
import About from "./content/About";
import Cta from "./content/Cta";
import Contact from "./content/Contact";
import Footer from "./Footer";
import Siper from "./content/Siper";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
</style>
