<template>
  <section ref="download" id="download" class="download">
    <div class="container">
      <div class="section-title">
        <span>Download</span>
        <h2>Download</h2>
      </div>
      <div class="">
        <p>You can download Siper brochure by clicking the link below.</p>

        <!-- <a :href="siper_brosur.pdf">lololo -->
        <button class="" @click="onClick()">
          <i class="info-box bx bx-download"></i>
          Download
        </button>
        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Download",
  methods: {
    onClick() {
      axios({
        url: "/ensiper_brosur.pdf",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ensiper_brosur.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>

<style scoped>
button {
  background: #cc1616;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  display: inline-block;
}

button:hover {
  background: #e82d2d;
}
</style>
