<template>
  <section id="topbar" class="d-flex align-items-center fixed-top">
    <div
      class="container d-flex justify-content-center justify-content-md-between"
    >
      <div class="contact-info d-flex align-items-center">
        <i class="bi bi-envelope-fill" />
        <a href="mailto:info@acikdenizbilisim.com">info@acikdenizbilisim.com</a>
        <i class="bi bi-phone-fill phone-icon"></i> +90 216-688-8567
      </div>
      <div class="social-links d-none d-md-block">
        <!-- <a href="#" class="twitter"><i class="bi bi-twitter"></i></a> -->
        <!-- <a href="#" class="facebook"><i class="bi bi-facebook"></i></a> -->
        <!-- <a href="#" class="instagram"><i class="bi bi-instagram"></i></a> -->
        <a href="https://www.linkedin.com/in/acikdenizbilisim" class="linkedin">
          <i class="bi bi-linkedin"></i>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped></style>
