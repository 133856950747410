<template>
  <TopBar />
  <Header />


  <main id="main" style="margin-top: 110px">
    <div class="entry container">
      <p style="text-align: center">
        <strong>Siper Uygulaması Gizlilik Politikası</strong>
      </p>
      <p>
        Açık Deniz Bilişim Danışmanlık ve Madencilik A.Ş (bundan böyle AçıkDeniz
        olarak anılacaktır), bilgi ve iletişim sağlayıcılarının uyması gereken
        yasalar uyarınca kullanıcı bilgilerinin gizliliğini korumayı ilke olarak
        benimsemiştir. AçıkDeniz, gizliliğinize, kişisel verilerinizin, trafik
        verilerinizin ve iletişim içeriğinizin gizliliğine saygı göstermektedir.
        İşbu Gizlilik Şartları, SİPER Uygulaması'nı (bundan böyle Uygulama
        ve/veya Servis olarak anılacaktır) kullanırken sağladığınız bilgilerin,
        AçıkDeniz tarafından nasıl kullanıldığını ve korunduğunu anlamanıza ışık
        tutacak şekilde hazırlanmıştır.
      </p>
      <p>
        Bu sebeple işbu Gizlilik Şartları doğrultusunda kişisel verilerinizin ne
        şekilde temin edilip, işlendiğini anlamanız ve kontrol edebilmeniz
        amacıyla, bu ilkelerin tarafınızca dikkatli bir şekilde okunması
        gerekmektedir. Uygulamamızı kullanmaya başlamanızla birlikte; zaman
        zaman değiştirilebilecek olan işbu Gizlilik İlkeleri kapsamında, kişisel
        verilerinizin paylaşılmasını ve işlenmesini kabul etmiş olacaksınız.
      </p>
      <ol>
        <li><strong>Gizlilik Şartlarının Kapsamı </strong></li>
      </ol>
      <p>
        İşbu Gizlilik Şartları, Uygulama kapsamındaki bütün işlemleri
        kapsamaktadır. Ayrıca aksi işbu Gizlilik Şartlarında belirtilmedikçe,
        burada kullanılmış olan bütün terimler, Uygulamaya ilişkin olarak
        hazırlanmış olan Kullanıcı Sözleşmesindeki terimlerle aynı anlama
        gelmektedir.
      </p>
      <p>
        Ayrıca işbu Gizlilik Şartları; (i) üçüncü taraf servisleri aracılığı ile
        elde edilmiş, ya da (ii) Uygulama kapsamında başkaca servislerin
        reklamını yapan diğer şirketler ve organizasyonlar aracılığı ile elde
        edilmiş bilgilere ilişkin olarak uygulanmayacaktır.
      </p>
      <ol start="2">
        <li><strong>Kişisel Verilerin Toplanması ve Kullanılması </strong></li>
      </ol>
      <p>
        Uygulama kapsamında belirtilen kategorilerdeki bilgiler dahil olmak
        üzere (ancak bunlarla sınırlı olmamak kaydıyla) sizin hakkınızda;
        kişisel ve kişisel olmayan bilgiler, konum bilgileri, log bilgileri ve
        paylaşılmış bilgiler toplanabilir ve kullanılabilir. Bu bilgiler en
        genel anlamında aşağıdaki gibi tanımlanmıştır:
      </p>
      <p>"Bilgi": Kişisel ve kişisel olmayan bilgiler.</p>
      <p>
        "Kişisel Bilgi": Sizi tanımlanabilir kılan ve sizinle alakalı bilgilerin
        tümü olarak ifade edilebilir. Kişisel bilgiler aşağıdaki hususları
        içerebilmektedir: – Servisi kullanmak üzere veya kullanıcı hesabı
        açılırken tarafınızca bize sunulan; isim, telefon numarası ve e-posta
        adresi gibi bilgileri; – Servisi kullanmanız sırasında paylaştığınız
        bilgiler dahil sizler tarafından Servis kapsamında sunulan her türlü
        bilgi; – Servisi kullandığınız esnada elde edilen konum ve log bilgileri
        gibi bilgiler.
      </p>
      <p>
        "Konum Bilgisi": Konumunuza ilişkin Uygulama kapsamında elde edilmiş
        bilgiler.
      </p>
      <p>
        "Log Verisi": Uygulamanın kullanılması sırasında; çerez, web işaretleri
        ve log dosyaları aracılığıyla otomatik olarak elde edilen teknik
        bilgileri;
      </p>
      <p>
        "Kişisel Olmayan Bilgi": Sizinle ilgili bilgi olmasına rağmen direkt
        olarak sizinle ilişkini kurulamayan, artık anonimleşmiş bilgiler. Bu
        sebeple Uygulama kapsamındaki hizmetlerin sunulabilmesi için aşağıdaki
        kişisel veriler toplanmaktadır:
      </p>
      <p>
        – Zorunlu Öğeler: Uygulama kapsamındaki servisin başlatılabilmesi için
        Kurum Kodu, Kullanıcı Adı ve Şifre, kullanıcı durum bilgisi, çerezler,
        ziyaret zamanı, hizmet kullanım kayıtları, uygunsuz kullanım kayıtları,
        e-posta adresleri ve sizinle SİPER arasındaki yazışmalar.
      </p>
      <p>
        – İsteğe Bağlı Öğeler: Kullanıcının konum bilgileri, kullanıcının profil
        fotoğrafı ve kullanıcının kendi yarattığı görseller.
      </p>
      <ol start="3">
        <li><strong>Kişisel Verilerin Toplanma ve Kullanım Amacı </strong></li>
      </ol>
      <p>
        Bilgi toplamaktaki temel amacımız size güvenli, kesintisiz, verimli ve
        özelleştirilmiş bir servis sağlamaktır. SİPER ve SİPER adına hareket
        eden hizmet sağlayıcılar, aşağıdaki işlemleri gerçekleştirmek için
        gerekli olan izin ve izin verilen şekilde sizinle ilgili kişisel
        verileri toplar ve kullanır:
      </p>
      <p>
        – Kullanıcı tarafından yaratılmış olan içeriklerin mobil veya benzeri
        diğer cihazlar aracılığı ile Servis kapsamında iletilmesi;
      </p>
      <p>– Servis kapsamındaki kimliğinizin tanımlanabilmesi;</p>
      <p>
        – Servis üyeliğinizin birçok cihaz tarafından kullanılabilmesi ya da
        Servis üyeliğinizin başkaca bir cihaza aktarılabilmesi; – Servisin diğer
        kullanan kullanıcılara ulaşılabilmesi;
      </p>
      <p>
        – Yine SİPER tarafından sağlanan diğer uygulama ve/veya servisler ile
        senkronize edilebilmesi; – Servis kapsamındaki izinsiz ve hileli
        kullanımların tespit edilebilmesi;
      </p>
      <p>
        – Uygulama kapsamındaki servislerin geliştirilebilmesi ve optimize
        edilebilmesi;
      </p>
      <p>
        – Servis kapsamında kullanıcıların taleplerine istinaden müşteri destek
        hizmetlerinin sunulabilmesi; – Kullanıcı memnuniyet anketlerinin
        düzenlenebilmesi;
      </p>
      <p>
        – Uygulamaya ilişkin olarak kullanıcılara güncel bilgi sağlanabilmesi; –
        İnternet ve/veya diğer ortamlarda kullandığımız reklamlara ilişkin
        verimlilik değerlerinin ölçümlenebilmesi;
      </p>
      <p>
        – Gerekli olduğu hallerde kullanıcıları yukarıda sayılanlar dışındaki
        diğer önemli gelişmelere ilişkin bilgi verilebilmesi;
      </p>
      <p>
        – Uygulama kapsamındaki servislere ilişkin olarak anonimleşmiş
        istatistiksel verilerin toplanabilmesi;
      </p>
      <p>
        – İlgili mevzuat ve diğer hukuki yükümlülüklere uyumluluk
        sağlanabilmesi.
      </p>
      <p>
        Ayrıca işbu Gizlilik Şartlarına bağlı kalmak kaydıyla, Uygulama
        kapsamındaki servislerin hizmet kalitesini arttırmak ve sizlere daha iyi
        bir deneyim sunmak amacıyla ya da sizler tarafından önceden onay alınmak
        suretiyle, AçıkDeniz'in diğer servislerinde (kümelenmiş ve
        bireyselleştirilmiş şekilde) de kullanmak amacıyla kişisel bilgileriniz
        işlenebilecektir. Hassas kişisel veri olarak tanımlanabilecek bilgiler
        açısından (ırk, etnik kimlik, dini ve ideolojik görüş, kişisel sağlık
        vb.) SİPER, diğer kişisel verilere nazaran çok daha katı bir Gizlilik
        Politikası benimsemiştir.
      </p>
      <ol start="4">
        <li><strong>Kişisel Verilerin Korunması </strong></li>
      </ol>
      <p>
        SİPER, kendisine sağlanan veya kendisi tarafından toplanan kişisel
        bilgileri ve trafik verilerini korumak için ilgili yükümlülükleri ve
        ilgili mevzuat çerçevesindeki istisnaları da gözeterek makul kurumsal ve
        teknik önlemleri almaktadır.
      </p>
      <p>
        Kişisel bilgilerinize ve trafik verilerine, sadece SİPER veya bağlı
        ortaklıkları, iştirakleri ya da hizmet sağlayıcılarının, kendilerine
        verilen görevleri yerine getirmek için verilere erişme ihtiyacı duyan
        yetkili çalışanları erişebilir. Uygulama kapsamındaki Servis ve
        yazılımı, on sekiz (18) yaşının altındaki bireylerin kullanımına sunmayı
        amaçlayarak yapılmamış olup, söz konusu durum için herhangi bir
        tasarlama yapılmamıştır.
      </p>
      <p>
        Ayrıca ebeveynin izni olmaksızın on sekiz (18) yaşının altında
        bireylerden bilgi toplanmadığından emin olmak için ebeveynlerin, söz
        konusu bireylerin işbu Gizlilik Şartlarına uygun hareket ettirmek
        amacıyla yönlendirmesi, SİPER tarafından beklenmektedir. SİPER ; (i)
        şüpheli istenmeyen postayı ve (ii) SİPER &nbsp;yazılımını, ürünlerini
        veya kullanıcılarını koruma çabalarının kapsamında anlık mesajlar içinde
        otomatik tarama yapabilir. Belirli durumlarda SİPER, bu çabalarla
        bağlantılı olarak anlık mesajları yakalayıp manuel olarak inceleyebilir.
        SİPER, tamamen kendi takdirine bağlı olarak, şüpheli ve istenmeyen
        içerikleri engelleyebilir, iletilmesini önleyebilir ve mesajlardaki
        şüpheli bağlantıları ya da içerikleri kaldırabilir.
      </p>
      <p>
        SİPER ayrıca kullanıcılarının kişisel verilerinin korunmasına yönelik
        olarak aşağıda sıralanan teknik ve idari yöntemleri kullanmaktadır:
      </p>
      <p>
        – SİPER, bilgisayar korsanlığı veya bilgisayar virüslerinden ötürü
        kullanıcılarının kişisel verilerinde meydana gelebilecek sızıntıları
        veya hasarları önlemek için her türlü önlemi almaktadır. SİPER dışarıdan
        izinsiz erişimi önlemek için bir güvenlik duvarının yanı sıra, düzenli
        olarak güvenliği sağlamak için de teknik donanımlar kullanmaktadır.
      </p>
      <p>
        – Servis kapsamında sadece yetkili kişilerin kişisel bilgileri
        işlemesine izin verilmektedir. Bu yetkililere ayrı ayrı parolalar
        atanmaktadır.
      </p>
      <p>
        – SİPER, işbu Gizlilik Şartlarının uygulanışını ve yetkili kişilerin
        şartlara uyup uymadıklarını kontrol etmek için iç prosedürler kullanır.
        Herhangi bir sorun tespit edilirse, SİPER sorunun düzeltilmesi için
        azami çaba gösterir. SİPER yetkisiz erişimlerin ve bilgilerinizin ifşa
        edilmesinin minimuma indirilmesine ilişkin işlemleri ve prosedürleri
        uygulamış olsa dahi, kişisel bilgilerinin kötüye kullanılmasına ilişkin
        tüm riskin ortadan kaldırılmasını garanti etmesi söz konusu olmaz.
      </p>
      <p>
        Parolanın yetkisiz kullanımı veya diğer güvenlik ihlallerinden haberdar
        olunmasının akabinde derhal SİPER yetkilileri ile iletişime geçilmesi
        gerekmektedir. Bununla birlikte SİPER, kullanıcıların ihmalinden veya
        internet sorunlarından kaynaklanan kişisel veri kayıplarından
        kaynaklanan hiçbir sorun ve zarardan sorumlu tutulamaz.
      </p>
      <p>
        – . Kişisel Verilerin İşlenmesinde Dış Kaynak Kullanımı SİPER şu an
        için, kişisel verilerin işlemesinde hizmetlerini daha iyi hale getirmek
        amacıyla kişisel verilerin saklanması için dış kaynaklar kullanabilir ve
        bu durumda SİPER, örneğin kullanıcıya bir dış kaynakla sözleşme
        yapıldığını açıkça bildirerek ve ilgili yasal mevzuata uyarak, kişisel
        verileri dış kaynak firmaları aracılığıyla saklayabilecektir.
      </p>
      <ol start="5">
        <li><strong>Kişisel Verilerin Saklanma Süresi </strong></li>
      </ol>
      <p>
        Kişisel verileriniz, ilgili yasal mevzuata ve yetkili mahkeme
        kararlarına uymak kaydıyla, Servis için gerekli olduğu sürece
        saklanmaktadır.
      </p>
      <p>
        Anlık mesajlar, sesli mesajlar ve görüntülü mesajların saklanmasına
        yönelik olarak ise; video, resim veya haritada konumunuz gibi,
        mesajlaşma özelliklerimiz aracılığıyla paylaştığınız tüm medya
        içerikleri de dahil olmak üzere anlık mesajlarınız, sesli mesajlarınız
        ve görüntülü mesajlarınız SİPER tarafından (i) mesajlarınızı iletmek ve
        senkronize etmek ve (ii) mesajlarını sizin tarafınızdan ve alıcılarınız
        tarafından görüntülenmesini sağlamak için saklanabilir. Saklanan
        bilgiler, yasal veya sözleşmesel saklama süresi sona erdiğinde veya
        SİPER'in artık gereksinimi kalmaması halinde SİPER tarafından
        silinebilecektir. Kişisel verilerinize ilişkin tüm sorularınız konusunda
        web sitemiz (<a href="https://www.siperiletisim.com"
          >www.siperiletisim.com</a
        >) üzerinden irtibata geçebilirsiniz.
      </p>
      <p>
        Size ait bilgilerin saklanıp ve bu bilgilerin kullanım alanlarına
        ilişkin sorularınız için SİPER ile yine aynı yöntemle iletişime
        geçebilirsiniz. Sorularınıza SİPER tarafından en kısa sürede yanıt
        verilmesi için en üst düzeyde çaba gösterileceğinden emin olabilirsiniz.
        Servis üyeliğinizin SİPER ya da sizin tarafınızdan herhangi bir sebeple
        iptal edilmesi halinde, SİPER makul bir süre içinde iptal edilen üyeliğe
        ilişkin her türlü kişisel verinin Servisten çıkarılmasına dair gerekli
        tüm tedbirleri alacaktır. Ancak Servis dahilinde sizin aracılığınızla
        üçüncü kişilerin elinde bulundurulan kişisel verilere ilişkin SİPER'in
        herhangi bir sorumluluğu bulunmamaktadır.
      </p>
      <ol start="6">
        <li><strong>Mobil Bildirimler </strong></li>
      </ol>
      <p>
        Mobil telefon aracılığı ile Servis kullanılırken, Servisin uygulamasını
        etkin bir şekilde çalıştırmadığınız veya kullanmadığınızda sohbetler ve
        diğer mesajlar hakkında bilgilendirmenizi sağlamak üzere bildirim
        hizmetleri kullanılabilir. Bu hizmetler üçüncü bir tarafça da
        sağlanabilecektir. Bildirim hizmetleri, hizmeti sunmanın bir parçası
        olarak mesaj gönderen kişi ve mesaj içeriği hakkında bilgi alabilir ve
        bu bilgileri hizmet sağlayıcısının şartlarına, koşullarına ve Gizlilik
        Şartlarına uygun bir şekilde kullanabilir.
      </p>
      <p>
        SİPER, üçüncü taraf bildirim hizmetlerinin topladığı verilerden sorumlu
        tutulamaz. Gelen mesajlar ile üçüncü taraf bildirim servislerini
        kullanarak bildirim alınmak istenmediği zaman, bu özellik uygulama
        içinden kapatılabilecektir.
      </p>
      <ol start="7">
        <li><strong>İzinli İletişim </strong></li>
      </ol>
      <p>
        Servisin kullanıcıları olan sizlere, Servis kapsamında gönderilecek
        AçıkDeniz ve AçıkDeniz grup şirketlerinin ve iş ortaklarının,
        servislerine ilişkin her türlü reklam, tanıtım ve yapmış olduğu ve/veya
        ileride yapacağı her türlü kampanyalara ait elektronik iletilerinin,
        ilgili yasal mevzuat uyarınca kullanıcılardan bu madde kapsamında
        sizlere ulaşmasını, bu Servisi kullanmaya başlamanızla kabul etmiş
        olacaksınız.
      </p>
      <ol start="8">
        <li><strong>Yaş Kısıtlamaları </strong></li>
      </ol>
      <p>
        Bu servisi kullananlar 18 (onsekiz) yaşını tamamlamış olduklarını beyan
        ederler. Bu servis 18 (onsekiz) yaş altındakilerin kullanımı için uygun
        değildir. 18 (onsekiz) yaşın altında olduğunu düşündüğünüz bir bireyin
        Servis kapsamında kişisel verisinin elde edildiğini veya işlendiğini
        düşünüyorsanız derhal&nbsp; SİPER Destek ile iletişime geçebilirsiniz.
        Ancak her halükarda Türk Mevzuatına tabi olarak yasal ve regurasyonel
        kısıtlamalar öncelikle geçerli ve bağlayıcı olacaktır.
      </p>
      <ol start="9">
        <li>
          <strong>Gizlilik Şartlarının ve Politikasının Değiştirilmesi </strong>
        </li>
      </ol>
      <p>
        Servis ve/veya yazılım değişiklikleri ve müşteri geri bildirimlerini
        yansıtmak için SİPER tarafından zaman zaman işbu Gizlilik Politikası
        güncellenmektedir. Gizlilik ilkelerinde önemli değişiklikler olması veya
        kişisel bilgilerinizi nasıl kullanacağımız konusunda değişiklikler
        yapılması halinde sizi bilgilendirmek için değişikliği uygulamadan önce
        bu tür değişikliklerle ilgili olarak kolaylıkla görünür şekilde bir
        bildirim yayınlanacaktır.
      </p>
      <p>
        Söz konusu bildirimin yayınlanmasının akabinde, ilgili Servisi
        kullanmaya devam etmeniz, işbu değiştirilmiş Gizlilik Politikasını kabul
        ettiğiniz anlamına gelmektedir. Servis dahilinde bilgilerinizin nasıl
        toplandığını, kullanıldığını ve korunduğunu öğrenmek için düzenli olarak
        bu Gizlilik Politikasını incelemenizi öneririz.
      </p>
      <ol start="10">
        <li><strong>Soru ve Destek </strong></li>
      </ol>
      <p>
        Eğer işbu gizlilik şartlarına ve uygulamalara ilişkin soru, öneri ve
        düşünceleriniz olursa web sitemiz (<a
          href="https://www.siperiletisim.com"
          >www.siperiletisim.com</a
        >) üzerinden irtibata geçebilirsiniz.
      </p>
    </div>
  </main>
  <Footer />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    ><i class="bi bi-arrow-up-short"></i
  ></a>
  <div id="preloader" />
</template>

<script>
import router from "../router";
export default {
  name: "Gizlilik",
  components: {
    Header,
    Footer,
    TopBar,
  },

  data: function () {
    return {
      loader: "",
    };
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
    );
    document.body.appendChild(plugin);
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "//cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.min.js"
    );
    document.body.appendChild(plugin2);
  },
  methods: {
    navigate() {
      router.push({ name: "Gizlilik" });
    },
  },
};

import Header from "./Header";
import TopBar from "./topbar/TopBar";
import Footer from "./Footer";
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/css/bootstrap.min.css";
@import "~boxicons/css/boxicons.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "../styles/style.css";
.entry {
  box-sizing: border-box;
  text-align: left;
}
</style>
