<template>
  <section id="siper" class="siper">
    <div style="text-align: center">
      <div
        style="
          background-color: #ff1a1a;
          vertical-align: middle;
          max-width: 900px;
          width: 75%;
          margin: auto;
          color: white;
        "
      >
        <h1 style="padding-top: 10px">WHAT IS SIPER?</h1>
      </div>
      <img
        src="@/assets/siper/siper_1705.jpg"
        style="max-width: 900px; width: 75%; height: auto"
      />
      <!--<img
        src="@/assets/siper/siper_1704.jpg"
        style="max-width: 900px; width: 75%; height: auto"
      /> -->
    </div>

    <div class="row" style="text-align: center; padding: 10px">
      <h2 style="padding-top: 50px">KEY FEATURES</h2>
      <div class="col-md-4">
        <i class="info-box bx bx-group" style="font-size: 32px"></i>
        <h4>REACH ALL EMPLOYEES IN THE ORGANIZATION</h4>
        <p style="padding-top: 50px">
          Get in your contacts, find the person you are looking for, contact go
          ahead, start communication now. All contacts in the institution are at
          your service.
        </p>
        <i class="info-box bx bx-table" style="font-size: 32px"></i>

        <h4>CREATE FLEXIBLE GROUPS</h4>
        <p style="padding-top: 50px">
          Create and manage your own groups. Business through Group
          Communication inform your group from every angle, be informed.
          Detailed group manage group permissions and communication with
          features.
        </p>
        <i class="info-box bx bx-file" style="font-size: 32px"></i>

        <h4>SHARE EVERY DOCUMENT</h4>
        <p style="padding-top: 50px">
          All kinds of documents, not just pictures, videos, locations, people.
          you can share. If your device supports these document types or You may
          need to install additional applications.
        </p>
      </div>
      <div class="col-md-4">
        <img
          src="@/assets/siper/image-047.png"
          style="max-width: 100%; height: auto"
        />
        <!-- <img
          src="@/assets/siper/image-043.png"
          style="max-width: 100%; height: auto"
        /> -->
      </div>
      <div class="col-md-4">
        <i class="info-box bi bi-phone" style="font-size: 32px"></i>
        <h4>RUN ON ANY DEVICE</h4>
        <p style="padding-top: 50px">
          Run on Android, Ios and Desktop devices. Never your business don't
          lose touch with him.
        </p>
        <i class="info-box bx bx-list-ul" style="font-size: 32px"></i>
        <h4>FILE AND ARCHIVE PROTECTION</h4>
        <p style="padding-top: 50px">
          Do not lose any shared messages and files. Continuous backup and you
          are working in an archived environment.
        </p>
        <i class="info-box bx bx-check-shield" style="font-size: 32px"></i>
        <h4>SAFE COMMUNICATION</h4>
        <p style="padding-top: 50px">
          Encrypt your communication end-to-end. The security of your business
          and private Shares are protected in different layers that only you can
          access. keep it in the structure. Not only with encryption, but also
          with artificial intelligence. intrusion detection, 2-level
          authentication and different network with the highest level of
          security and protection is provided.
        </p>
      </div>
    </div>

    <div style="text-align: center; background-color: #ccebff; padding: 20px">
      <div style="">
        <img
          src="@/assets/siper/image-004.png"
          style="max-width: 50%; height: auto"
        />
        <h3 style="padding-top: 50px">Advantages over the competititors</h3>
        <ul
          class="list2"
          style="
            padding-top: 50px;
            text-align: left;
            margin: auto;
            max-width: 700px;
          "
        >
          <li>
            ✓ Ability to work on any platform <br /><strong
              >Smart phone :</strong
            >
            Android, IOS <br /><strong>Desktop :</strong> Windows, Mac, Linux
          </li>
          <li>✓ Non-crackable encryption technology</li>
          <li>✓ Integration with the enterprise active directory</li>
          <li>✓ Installation on enterprise-specific servers</li>
          <li>✓ Communication between different enterprise</li>
          <li>✓ Secure archiving of files and messages</li>
          <li>✓ Ability to work with crypto</li>
          <li>✓ Compatibility with Pardus</li>
          <li>✓ Compliance with Enterprise Firewalls</li>
          <li>✓ Multiple authentication</li>
          <li>✓ High-capacity audio conferencing</li>
          <li>✓ Smart group and distribution lists privilege</li>
          <li>
            ✓ Ability to make quality video calls and multi-user conferencing
          </li>
          <li>
            ✓ Ability to restrict the access of users who left the institution
            with its possibility of turning off the offline feature
          </li>
        </ul>
      </div>
    </div>
    <div style="text-align: center; padding: 50px">
      <img
        src="@/assets/siper/image-089.jpg"
        style="max-width: 50%; height: auto"
      />
      <!--<img
        src="@/assets/siper/image-057.png"
        style="max-width: 50%; height: auto"
      /> -->
      <div
        style="
          max-width: 700px;
          text-align: left;
          vertical-align: middle;
          margin: auto;
        "
      >
        <p>
          SİPER is an application that allows highly secure messaging and
          conversations with the developed encrypted messaging methods, and is
          also very suitable for customization with its flexible structure and
          flexible Back-Ends infrastructure that can be integrated into
          institutions and any enterprises. With customized solutions, a
          structure has been developed that allows messages to be archived if
          necessary, enables the synchronous use of more than one device at the
          same time, and provides services to users on every platform with iOS,
          Android, Windows, Linux and Mac OS X platforms. In summary, SİPER is
          one step ahead of its competitors among instant messaging applications
          with its high-level security; its ability to be installed on your own
          servers, to work from the corporate active directory, different
          business-specific features and its development/customization
          flexibility.
        </p>
      </div>
    </div>

    <div style="position: relative; height: 950px">
      <img
        src="@/assets/siper/image-045.png"
        style="background-position: center center"
      />
      <div
        style="
          position: absolute;
          width: 80%;
          text-align: center;
          letter-spacing: 0px;
          font-weight: 400;
          font-size: 16px;
          right: 10%;
          color: rgb(255, 255, 255);
          top: 50px;
        "
      >
        <div
          style="
            max-width: 900px;
            background-color: #ff1a1a;
            text-align: left;
            vertical-align: middle;
            padding: 10px;
            margin: auto;
          "
        >
          <h3>IS SIPER SAFE?</h3>
          <p>
            In public communication applications, you are not in control, it is
            in others.<br /><br />
            <strong
              >SİPER protects all your communications like a castle, because you
              manage your communication with SİPER.</strong
            >
            <br /><br />
            SİPER users are defined and logged into the system in a special way.
            Each message is encrypted end-to-end while a private key is
            generated for the identity. Special encryption methods are used
            instead of security layers such as SSL and Firewall, and the
            firewall is reshaped with each message. SİPER is not just a
            messaging software, it also performs the task of attack detection
            and prevention as a layer. Its purpose is not only to encrypt it,
            but also to keep the server within the security circle.
          </p>
        </div>
        <h4 style="padding-top: 20px">
          NOW INTERNAL COMMUNICATION IS CHANGING
        </h4>

        <img
          src="@/assets/siper/image-055.png"
          style="max-width: 275px; width: 30%; height: auto; padding: 10px"
        />
        <img
          src="@/assets/siper/iso27001.jpg"
          style="max-width: 391px; width: 40%; height: auto; padding: 10px"
        />
        <img
          src="@/assets/siper/image-056.png"
          style="max-width: 275px; width: 30%; height: auto; padding: 10px"
        />

        <h4>CERTIFIED AND APPROVED</h4>
        <p>
          SAFETY AND PENETRATION TESTS HAVE BEEN DONE AND ISO 27001 HAS
          CERTIFICATE.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Siper",
  components: {},
};
</script>

<style>
.cta-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta-btn:hover {
  background: #cc1616;
  border: 2px solid #cc1616;
}

.siper ul {
  list-style: none;
}

.siper .list2 ul li:before {
  content: "✓ ";
}
</style>
