<template>
  <section id="hero" class="d-flex align-items-center">
    <div id="slideshow-example" data-component="slideshow">
      <div role="list">
        <div class="slide">
          <img src="@/assets/siper/image-069.png" />
          <div class="sld-contnt1">
            <h1>Artık Kurum İçi İletişim Değişiyor</h1>
            <img src="@/svg/siper.svg" />
            <br />
            <h1>Siper</h1>
            <a class="cta-btn" href="#contact">İletişime Geç</a>
          </div>
        </div>

        <div class="slide">
          <img src="@/assets/siper/image-002.png" />
          <div class="sld-contnt2">
            <img
              src="@/assets/yerlivemilli.png"
              width="200"
              height="200"
              style="padding-top: 20px"
            />
            <h1>Kendi Sunucularınızda Çalışabilen</h1>
            <p>Kurum İçi Güvenli İletişim Platformu</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Hero -->

  <!-- demo root element -->
</template>

<script>
export default {
  name: "Hero",
  mounted: function () {
    var slideshows = document.querySelectorAll('[data-component="slideshow"]');

    // Apply to all slideshows that you define with the markup wrote
    slideshows.forEach(initSlideShow);
  },
};

function initSlideShow(slideshow) {
  var slides = document.querySelectorAll(
    `#${slideshow.id} [role="list"] .slide`
  ); // Get an array of slides

  var index = 0,
    time = 5000;
  slides[index].classList.add("active");

  setInterval(() => {
    slides[index].classList.remove("active");
    slides[index].style.display = "none";

    //Go over each slide incrementing the index
    index++;

    // If you go over all slides, restart the index to show the first slide and start again
    if (index === slides.length) index = 0;

    slides[index].style.display = "block";
    slides[index].classList.add("active");
  }, time);
}
</script>

<style scoped>
[data-component="slideshow"] .slide {
  display: none;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

[data-component="slideshow"] .slide.active {
  display: block;
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
/* Fading animation */
.fade {
}
-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.sld-contnt1 {
  position: absolute;
  width: 80%;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
  right: 10%;
  color: rgb(255, 255, 255);
  top: 30%;
}
.sld-contnt1 img {
  width: 5%;
}

.sld-contnt2 {
  position: absolute;
  width: 80%;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 16px;
  right: 10%;
  color: rgb(255, 255, 255);
  top: 15%;
}
.sld-contnt2 img {
  width: 20%;
}

.slide p {
  font-size: 40px;
  font-weight: bold;
}
@media (max-width: 768px) {
  #hero {
    text-align: center;
    font-size: 10px;
  }
  #hero .container {
    padding-top: 40px;
  }
  #hero h1 {
    font-size: 14px;
    /*line-height: 36px;*/
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
</style>
