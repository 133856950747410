<template>
  <header id="header" class="d-flex align-items-center fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <!-- <h1 class="logo">
        <a href="index.html">Açık Deniz Bilişim</a>
      </h1>
      Uncomment below if you prefer to use an image logo -->
      <a href="/enhome" class="logo">
        <img src="@/svg/siper.svg" alt="" class="img-fluid" />
        <h1
          style="
            color: black;
            float: right;
            font-weight: bold;
            margin-top: 11px;
          "
        >
          Siper
        </h1>
      </a>

      <div id="navbar" :class="'navbar ' + mobile">
        <ul>
          <NavItem to_ref="/enhome" txt="Home" class="nav-link scrollto" />
          <NavItem
            to_ref="/enhome/#about"
            txt="About"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <NavItem
            to_ref="/enhome/#download"
            txt="Download"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <NavItem
            to_ref="/enhome/#contact"
            txt="Contact"
            class="nav-link scrollto"
            @click="onItemClick"
          />
          <li>
            <a href="/" class="nav-link"
              >Türkçe
              <img
                src="@/assets/turkey-icon.png"
                alt=""
                class="img-fluid"
                style="margin-left: 5px"
              />
            </a>
          </li>
        </ul>
        <i
          :class="'bi mobile-nav-toggle ' + x_button"
          @click="mobileNavToggle"
        ></i>
      </div>
    </div>
  </header>
</template>

<script>
import NavItem from "./nav/NavItem";

export default {
  name: "Header",
  data: function () {
    return {
      test: 0,
      mobile: "",
      x_button: "bi-list",
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: {
    NavItem,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    mobileNavToggle() {
      if (this.test === 0) {
        this.mobile = "navbar-mobile";
        this.x_button = "bi-x";

        this.test = 1;
      } else {
        this.mobile = "";
        this.x_button = "bi-list";

        this.test = 0;
      }
    },
    onItemClick() {
      if (this.windowWidth < 991) {
        if (this.test === 0) {
          this.mobile = "navbar-mobile";
          this.x_button = "bi-x";

          this.test = 1;
        } else {
          this.mobile = "";
          this.x_button = "bi-list";

          this.test = 0;
        }
      }
    },
  },
};
</script>

<style scoped></style>
