<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="section-title">
        <span>İletişim</span>
        <h2>İletişim</h2>
        <p>
          Aşağıdaki iletişim bilgilerimizi kullanarak bize her daim
          ulaşabilirsiniz
        </p>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3></h3>
            <p>
              Uphill Court A1 Blok No:3 Kat:4 D:22 34746 Batı Ataşehir/İstanbul
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-envelope"></i>
            <h3></h3>
            <p>info@acikdenizbilisim.com</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-phone-call"></i>
            <h3></h3>
            <p>+90 216 688 85 67</p>
          </div>
        </div>
      </div>

      <div class="row" data-aos="fade-up">
        <div class="col-lg-6">
          <div class="info-box mb-4">
            <i class="bx bx-map"></i>
            <h3></h3>
            <p>
              Ankara Üniversitesi Teknokent, Bahçelievler Mah. 319. Cad. No:35,
              E Blok Z-19 <br />06830 Gölbaşı Ankara
            </p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-envelope"></i>
            <h3></h3>
            <p>ankara@acikdenizbilisim.com</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box mb-4">
            <i class="bx bx-phone-call"></i>
            <h3></h3>
            <p>+90 312 484 57 58</p>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-lg-12">
          <div class="info-box mb-4">
            <i class="bi bi-link"></i>
            <h3></h3>
            <p>
              <a href="https://acikdenizbilisim.com" target="blank">
                www.acikdenizbilisim.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-lg-6">
          <iframe
            class="mb-4 mb-lg-0"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12045.2817522482!2d29.1112584!3d40.9963591!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb260c02adcc28c9d!2zQcOHSUsgREVOxLBaIELEsEzEsMWexLBN!5e0!3m2!1str!2str!4v1620589827281!5m2!1str!2str"
            style="border: 0; width: 100%; height: 384px; border: 0"
            frameborder="0"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </div>

        <div class="col-lg-6">
          <!-- localhost php -->
          <!--
          <form
            action="http://localhost/contact.php"
            method="post"
            role="form"
            class="php-email-form"
          >
          -->
          <form v-on:submit.prevent="submitForm" class="php-email-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="Ad Soyad"
                  required
                  v-model="form.name"
                />
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="E-posta"
                  required
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                placeholder="Konu"
                required
                v-model="form.subject"
              />
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                placeholder="Mesaj"
                required
                v-model="form.message"
              ></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Mesajınız gönderildi! Teşekkürler.</div>
            </div>
            <div class="text-center">
              <button type="submit" @click="sendMessage">Mesaj Gönder</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      const options = {
        method: "post",
        //contact.php
        url: "../contact.php",
        data: this.form,
        headers: { "Content-Type": "application/json" },
      };
      // send the request
      axios(options);
      alert("Mesajınız alınmıştır.");
      // empty form
      var self = this;
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = "";
      });
    },
  },
};
</script>
<style scoped>
.row a {
  text-decoration: none;
  color: #444444;
}
</style>
