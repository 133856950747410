<template>
  <section id="siper" class="siper">
    <div style="text-align: center">
      <div
        style="
          background-color: #ff1a1a;
          vertical-align: middle;
          max-width: 900px;
          width: 75%;
          margin: auto;
          color: white;
        "
      >
        <h1 style="padding-top: 10px">SİPER NEDİR?</h1>
      </div>
      <img
        src="@/assets/siper/siper_1704.jpg"
        style="max-width: 900px; width: 75%; height: auto"
      />
    </div>

    <div class="row" style="text-align: center; padding: 10px">
      <h2 style="padding-top: 50px">TEMEL ÖZELLİKLER</h2>
      <div class="col-md-4">
        <i class="info-box bx bx-group" style="font-size: 32px"></i>
        <h4>KURUMDAKİ TÜM ÇALIŞANLARA ULAŞIN</h4>
        <p style="padding-top: 50px">
          Rehberinizde olsun olması, aradığınız kişiyi hemen bulun, irtibata
          geçin, iletişime hemen başlayın. Kurumdaki tüm rehber hizmetinizde
          olsun.
        </p>
        <i class="info-box bx bx-table" style="font-size: 32px"></i>

        <h4>ESNEK GURUPLAR OLUŞTURUN</h4>
        <p style="padding-top: 50px">
          Kendi gruplarınızı kurup yönetin. Grup iletişim sayesinde iş konusunda
          her açıdan grubunuzu bilgilendirin, bilgilenin. Detaylı grup
          özellikleri sayesinde grup yetkilerini ve iletişimi yönetin.
        </p>
        <i class="info-box bx bx-file" style="font-size: 32px"></i>

        <h4>HER DÖKÜMANI PAYLAŞIN</h4>
        <p style="padding-top: 50px">
          Sadece resim, video, lokasyon , kişi değil her türlü dokümanı
          paylaşabilirsiniz. Bu doküman tiplerini cihazınızın desteklemesi yada
          ek uygulama kurmanız gerekebilir.
        </p>
      </div>
      <div class="col-md-4">
        <img
          src="@/assets/siper/image-043.png"
          style="max-width: 100%; height: auto"
        />
      </div>
      <div class="col-md-4">
        <i class="info-box bi bi-phone" style="font-size: 32px"></i>
        <h4>HER CİHAZDA ÇALIŞTIRIN</h4>
        <p style="padding-top: 50px">
          Android, Ios ve Desktop cihazlarda çalıştırın. Hiçbir zaman işletmeniz
          ile bağlantınız kopmasın.
        </p>
        <i class="info-box bx bx-list-ul" style="font-size: 32px"></i>
        <h4>DOSYA VE ARŞİV KORUMASI</h4>
        <p style="padding-top: 50px">
          Paylaşılan hiçbir mesajı ve dosyayı kaybetmeyin. Sürekli yedek alınan
          ve arşivlenen bir ortamda çalışıyorsunuz.
        </p>
        <i class="info-box bx bx-check-shield" style="font-size: 32px"></i>
        <h4>GÜVENLİ İLETİŞİM</h4>
        <p style="padding-top: 50px">
          İletişiminiz uçtan uca şifrelensin. İşletmenizin güvenliği ve özel
          paylaşımları sadece sizin erişebileceğiniz farklı katmanlarda korunan
          yapıda saklansın. Sadece şifreleme değil aynı zamanda yapay zeka ile
          saldırı tespiti, 2 seviyeli kimlik doğrulama ve farklı network
          seviyesindeki korumalar ile ulaşılabilecek en üst seviye güvenlik ve
          koruma sağlanmaktadır.
        </p>
      </div>
    </div>

    <div style="text-align: center; background-color: #ccebff; padding: 20px">
      <div style="">
        <img
          src="@/assets/siper/image-004.png"
          style="max-width: 50%; height: auto"
        />
        <h3 style="padding-top: 50px">EMSALLERİNE GÖRE ÜSTÜNLÜKLERİ</h3>
        <ul
          class="list2"
          style="
            padding-top: 50px;
            text-align: left;
            margin: auto;
            max-width: 700px;
          "
        >
          <li>
            ✓ Her platformda özgürce çalışılabilme <br /><strong
              >Akıllı Telefon :</strong
            >
            Android, IOS <br /><strong>Desktop :</strong> Windows, Mac, Linux
          </li>
          <li>✓ Kırılmayan şifreleme teknolojisi</li>
          <li>✓ Kurum rehberine entegre olabilme</li>
          <li>✓ Kuruma özel sunuculara kurulum</li>
          <li>✓ Farklı kurumlar arasında iletişim</li>
          <li>✓ Dosya ve mesajları güvenle arşivleme</li>
          <li>✓ Kriptolu çalışabilme</li>
          <li>✓ Pardus’a uyumluluk</li>
          <li>✓ Kurum Firewall’larına uyum</li>
          <li>✓ Çoklu kimlik doğrulayabilme</li>
          <li>✓ Yüksek kapasiteli sesli konferans yapabilme</li>
          <li>✓ Akıllı grup ve dağıtım listeleri ayrıcalığı</li>
          <li>
            ✓ Kaliteli video görüşmeleri yapabilme ve yüksek kullanıcılı
            konferans özelliği
          </li>
          <li>
            ✓ Offline özelliği kapatabilme imkanı ile kurumdan ayrılan kullanıcı
            erişimini kısıtlayabilme
          </li>
        </ul>
      </div>
    </div>
    <div style="text-align: center; padding: 50px">
      <img
        src="@/assets/siper/image-057.png"
        style="max-width: 50%; height: auto"
      />
      <div
        style="
          max-width: 700px;
          text-align: left;
          vertical-align: middle;
          margin: auto;
        "
      >
        <p>
          SİPER, geliştirilen şifreli mesajlaşma yöntemleriyle son derece
          güvenli mesajlaşmaya ve görüşmelere imkan veren, aynı zamanda esnek
          yapısıyla ve kurumlara entegre olabilecek esnek Back-Ends altyapısıyla
          özelleştirilmeye çok müsait bir uygulamadır. Özelleştirilmiş
          çözümlerle, gerekirse mesajların arşivlenmesine imkan sağlayan, aynı
          anda birden fazla aygıtın senkron olarak kullanılmasını mümkün kılan,
          iOS, Android, Windows, Linux ve Mac OS X platformları ile her
          platformda kullanıcıya hizmet sunan bir yapı geliştirilmiştir. Özetle,
          SİPER anlık mesajlaşma uygulamaları arasında üst seviye güvenli
          olması, kendi sunucularınıza kurulabilmesi, kurum rehberinden
          çalışabilmesi, iş hayatına özel farklı özelliklerinin bulunması ve
          geliştirme/ özelleştirme esnekliğiyle rakiplerine göre bir adım önde
          yer almaktadır.
        </p>
      </div>
    </div>

    <div style="position: relative; height: 950px">
      <img
        src="@/assets/siper/image-045.png"
        style="background-position: center center"
      />
      <div
        style="
          position: absolute;
          width: 80%;
          text-align: center;
          letter-spacing: 0px;
          font-weight: 400;
          font-size: 16px;
          right: 10%;
          color: rgb(255, 255, 255);
          top: 50px;
        "
      >
        <div
          style="
            max-width: 900px;
            background-color: #ff1a1a;
            text-align: left;
            vertical-align: middle;
            padding: 10px;
            margin: auto;
          "
        >
          <h3>SİPER GÜVENLİ Mİ?</h3>
          <p>
            Herkese açık iletişim uygulamalarında kontrol sizde değil,
            başkalarındadır.<br /><br />
            <strong
              >SİPER tüm iletişiminizi bir kale gibi korur, çünkü kontrol
              sizdedir.</strong
            >
            <br /><br />
            SİPER kullanıcıları özel bir şekilde sisteme tanımlanır ve giriş
            yapar. Özel bir anahtar kimlik için üretilirken aynı zamanda her
            mesaj uçtan uca şifrelenir. SSL, Firewall gibi güvenlik katmanları
            yerine özel şifreleme yöntemleri kullanılır ve koruma duvarı her
            mesajla yeniden şekillendirilir. SİPER sadece bir mesajlaşma
            yazılımı olmayıp, bir katman olarak saldırı tespiti ve önleme
            görevini de yapar. Amacı sadece şifrelemek değil, sunucuyu da
            güvenlik çemberi içinde tutmaktır.
          </p>
        </div>
        <h4 style="padding-top: 20px">ARTIK KURUM İÇİ İLETİŞİM DEĞİŞİYOR</h4>

        <img
          src="@/assets/siper/image-055.png"
          style="max-width: 275px; width: 30%; height: auto; padding: 10px"
        />
        <img
          src="@/assets/siper/iso27001.jpg"
          style="max-width: 391px; width: 40%; height: auto; padding: 10px"
        />
        <img
          src="@/assets/siper/image-056.png"
          style="max-width: 275px; width: 30%; height: auto; padding: 10px"
        />

        <h4>SERTİFİKALI VE ONAYLI</h4>
        <p>
          GÜVENLİK VE PENETRASYON TESTLERİ YAPILMIŞTIR VE ISO 27001
          SERTİFİKASINA SAHİPTİR.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Siper",
  components: {},
};
</script>

<style>
.cta-btn {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta-btn:hover {
  background: #cc1616;
  border: 2px solid #cc1616;
}

.siper ul {
  list-style: none;
}

.siper .list2 ul li:before {
  content: "✓ ";
}
</style>
